<template>
  <div class="constrain part">
    <div class="row-12 search-section">
      <div class="sm:col-6 sm:offset-3 md:col-4 md:offset-8 relative">
        <input v-model="searchInput" type="text" :placeholder="payload.suchen">
        <Search class="search-icon" />
      </div>
      <div class="col-12 divider mb-12 mt-8" />
    </div>
    <div class="row-12">
      <div class=" md:col-12">
        <div class="row-12 gutter-4 md:gutter-12">
          <div v-for="member in filteredMembers" :key="member.id" class="md:col-6 flex">
            <div class="member-box bg-white">
              <img
                v-if="member.logo"
                class="member-logo mb-8"
                :src="member.logo.large"
                :alt="member.logo.alt"
              >
              <h3 class="mb-4 md:mb-8" v-html="member.firma" />
              <div class="row-12">
                <div class="lg:col-5" v-html="member.adresse" />
                <div class="lg:col-7">
                  <div>
                    <a v-if="member.telefon" :href="'tel:' + member.telefon">Tel: {{ member.telefon }}</a>
                  </div>
                  <div>
                    <a v-if="member.fax" :href="'tel:' + member.fax">Fax: {{ member.fax }}</a>
                  </div>
                  <div>
                    <a v-if="member.email" :href="'mailto:' + member.email">{{ member.email }}</a>
                  </div>
                  <div>
                    <a v-if="member.link" class="weblink" :href="member.link">{{ member.link }}</a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Search from '../../assets/images/search.svg';

export default {
  components: {
    Search,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      searchInput: '',
    };
  },
  computed: {
    members() {
      return this.payload.verbandsmitglieder;
    },
    filteredMembers() {
      const searchTerm = new RegExp(this.searchInput, 'gi');
      const membersWithSearchString = this.payload.verbandsmitglieder.map((mitglied) => ({
        ...mitglied,
        infosCombined: `${mitglied.firma} ${mitglied.adresse} ${mitglied.email} ${mitglied.link} 
        ${mitglied.telefon} ${mitglied.fax}`,
      }));
      return membersWithSearchString.filter((member) => member.infosCombined.match(searchTerm));
    },
  },
  mounted() {
    // console.log(this.payload);
  },
};
</script>

<style lang="scss" scoped>
.divider {
  border-bottom: 2px solid var(--color-light-blue);
}

input {
  font-weight: 700;
  color: var(--color-navy);
  padding: px(10) px(55) px(10) px(25);
  width: 100%;
  border: 2px solid var(--color-blue);
  border-radius: px(100);
  outline: none;
  &::placeholder {
    text-transform: uppercase;
    color: var(--color-navy);
  }
}

.search-icon {
  background-color: var(--color-white);
  position: absolute;
  height: 50%;
  top: 50%;
  right: px(20);
  transform: translateY(-50%);
  color: var(--color-navy);
}

.member-box {
  padding: 2rem;
  width: 100%;
}
.member-logo {
  max-height: px(40);
  @screen md {
    max-height: px(50);
  }
  @screen lg {
    max-height: px(75);
  }
}

:deep(.member-box) {
  h3 {
    font-size: px(22);
    @screen sm {
      font-size: px(24);
    }
    @screen md {
      font-size: px(26);
    }
    @screen lg {
      font-size: px(30);
    }
  }
  p, a {
    line-height: 1.3;
    color: var(--color-navy);
    // word-break: break-all;
    font-size: px(18);
    @screen md {
      font-size: px(20);
    }
    @screen lg {
      font-size: px(22);
    }
  }
  a[href^="mailto"], .weblink {
    color: var(--color-blue);
    text-decoration: underline;
  }
}

</style>
